@import "src/assets/styles/abstracts/variables";

.gallery {
  &__section {
    position: relative;
    z-index: 4;
    &::after {
      content: "";
      background: url("/assets/images/bg/bg-gallery.png") no-repeat 50% 20% / cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  &__content {
    padding: 12rem 0;
    width: 95%;
    margin: 0 auto;
    .section-title {
      margin-bottom: 5rem;
    }
    &-items {

    }
    &-item {
      margin-bottom: 9rem;
      gap: 5rem;
      @media (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
          &:nth-child(2n) {
            flex-direction: row-reverse;
          }
      }

      &:last-child {
        margin-bottom: 0;
      }
      h2 {
        width: 39%;
        text-align: center;
        position: relative;
        z-index: 3;
        span {
          display: block;
          width: 50%;
          margin: 0 auto;
          padding-top: 2rem;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      img {
        width: 100%;
      }
    }
  }

  &__inner {
    background: url("/assets/images/bg/bg-body-min-2.jpg") no-repeat 50% 20% / cover;
  }

  &__slider {
    overflow: visible;

    img {
      box-shadow: 0 0 4.3rem rgba(102, 234, 255, 0.8), 1rem 0 4.3rem rgba(102, 234, 255, 0.8), -1rem 0 6.3rem rgba(102, 234, 255, 0.8);
      width: 100%;
    }
  }

  &__item {
    position: relative;
  }

  &__item-inner {
    position: relative;
  }

  &__note {
    bottom: calc(100% + 1.25rem);

    &--pos-1 {
      right: calc(38% + 3rem);
    }

    &--pos-2 {
      left: calc(25.5% + 3rem);
    }

    &--pos-3 {
      right: calc(28% + 3rem);
    }
  }

  @media (min-width: 768px) {
    &__inner {
      margin: 0 -1.5rem;
    }

    &__item {
      padding: 0 1.5rem;
    }

  }

  @media (max-width: 767px) {
    &__inner {
      padding: 0 36px;
    }

    &__item {
      padding: 0 10px;
    }
  }
}

