html {
  @media (min-width: 2560px) {
    font-size: 21px;
  }

  @media (min-width: 3440px) {
    font-size: 22px;
  }

  @media (max-width: 1440px) {
    font-size: 12px;
  }

  @media (max-width: 550px) {
    font-size: 10px;
  }
}

body {
  font-family: $primaryFont;
  font-size: 1rem;
  font-weight: normal;
  color: $copy;

  @media (max-width: 1440px) {
    font-size: 1.33rem;
  }
}

// .gradient-text {
//   background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
//   background-clip: text;
//   color: transparent;
// }

.section-title {
  font: 400 5rem/1.12 $secondaryFont;
  text-align: center;
  text-shadow: $shadow;
  margin: 0 0 2rem;

  @media (max-width: 767px) {
    font-size: 3.4rem;
  }
}
.secondary-title {
  font-size: 3.125rem;
  font-weight: 300;

}

.mb-button.mb-button__text-large {
  font-size: 1.25rem;
}

.section-text {
  font-size: 1.5rem;
  line-height: 1.4;
}

