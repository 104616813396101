body {
  background: $bg-body;
}

.main {
  @media (min-width: 768px) {
    padding: 2.5rem 0 0;
  }
}

.container{
  max-width: 85.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
}
