.mr-8 {
  margin-right: 8px;
}
.mr-24 {
  margin-right: 24px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-80 {
  margin-bottom: calc(2.5rem + ((1vw - 3.2px) * 2.5));
}
