.note {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  background: $bg-noise;
  background-blend-mode: overlay, soft-light, normal;
  box-shadow: $shadow-lg;
  backdrop-filter: blur(30px);
  border-radius: .625rem;
  font-size: 1.125rem;
  line-height: 1.33;
  &.hero {
    bottom: 100% !important;
    left: 50% !important;
    right: initial !important;
    transform: translate(-50%, 0);
    width: 77%;
    text-align: center;
    height: 6rem;
    justify-content: center;
    text-shadow: 0px 0px 5px rgba(102, 234, 255, 0.8), 0px 0px 15px rgba(102, 234, 255, 0.8), 0px 0px 20px rgba(102, 234, 255, 0.3);
    .note__logo {
        background: $bg-noise;
        position: absolute;
        left: 50%;
        bottom: 100%;
        transform: translate(-50%, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 1rem;
        backdrop-filter: blur(60px);
        box-shadow: $shadow-lg;
        img {
          height: 2rem !important;
          box-shadow: none !important;
        }
    }
  }

  @media (max-width: 1024px) {
    padding: 1.2rem;
    font-size: 1rem;
  }

  @media (max-width: 767px) {
    &.gallery__note {
      opacity: 0;

      .swiper-slide-active & {
        opacity: 1;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    width: 3rem;
    height: 5.875rem;
    border: 1px solid #fff;

    &:after {
      position: absolute;
      bottom: 0;
      width: 4px;
      height: 4px;
      transform: rotate(45deg);
      border: 1px solid #fff;
      border-width: 0 1px 1px 0;
      content: "";
    }

    &--right {
      left: 100%;
      border-width: 1px 1px 0 0;
      border-radius: 0 .625rem 0 0;

      &:after {
        right: -3px;
      }
    }

    &--left {
      right: 100%;
      border-width: 1px 0 0 1px;
      border-radius: .625rem 0 0 0;

      &:after {
        left: -3px;
      }

      &.reverse {
        bottom: 50%;
        top: auto;
        transform: scaleY(-1);
      }
    }

    &--bottom-left {
      top: 100%;
      right: 50%;
      left: auto;
      border-width: 0 1px 1px 0;
      border-radius: 0 0 .625rem 0;

      &:after {
        transform: rotate(132deg);
        margin-bottom: -3px;
      }
    }

    &--top-straight {
      right: 50%;
      bottom: 100%;
      top: auto;
      width: 0;
      border-width: 0 1px 0 0;

      &:after {
        bottom: 100%;
        transform: rotate(-136deg);
        margin: 0 0 -3px -2px;
      }
    }

    &--top-left {
      right: 50%;
      bottom: 100%;
      top: auto;
      border-width: 1px 1px 0 0;
      border-radius: 0 .625rem 0 0;

      &:after {
        bottom: 100%;
        left: 0;
        transform: rotate(132deg);
        margin-bottom: -2px;
      }
    }

    &--lg {
      height: 13.2rem;
    }
  }

  &__nb {
    margin: 0 1rem 0 0;
    font-size: 2.5rem;
    line-height: .6;
    font-weight: 300;
  }

  &__icons {
    display: flex;
    align-items: center;
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;

    &--before {
      margin-left: -3.25rem;
      margin-right: .5rem;

      &.note__icons--multi {
        margin-left: -4.2rem;
      }
    }

    &--after {
      margin-right: -3.25rem;
      margin-left: .5rem;

      &.note__icons--multi {
        margin-right: -4.2rem;
      }
    }

    mb-icon {
      margin: 0 .5rem 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}
