.mb-icon-round {
  &.mb-icon-medium {
    .mb-icon-size-lg & {
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  .mb-icon-green & {
    text-shadow: 0px 0px 5px #66FFB6, 0px 0px 15px rgba(102, 255, 182, 0.8), 0px 0px 20px #66FFB6;
  }

  .mb-icon-purple & {
    text-shadow: 0px 0px 5px rgba(171, 102, 255, 0.8), 0px 0px 15px rgba(171, 102, 255, 0.8), 0px 0px 20px rgba(171, 102, 255, 0.8);
  }

  .mb-icon-bg-1 & {
    background: url($noiseUrl), linear-gradient(90deg, rgba(171,88,114,1) 0%, rgba(127,55,74,1) 100%) !important;
  }

  .mb-icon-bg-2 & {
    background: url($noiseUrl), linear-gradient(90deg, rgba(131,85,13,1) 0%, rgba(91,70,22,1) 100%) !important;
  }

  .mb-icon-bg-3 & {
    background: url($noiseUrl), linear-gradient(90deg, rgba(75,83,47,1) 0%, rgba(44,55,31,1) 100%) !important;
  }

  .mb-icon-bg-4 & {
    background: url($noiseUrl), linear-gradient(90deg, rgba(128,92,70,1) 0%, rgba(110,72,46,1) 100%) !important;
  }

  .mb-icon-bg-5 & {
    background: url($noiseUrl), linear-gradient(90deg, rgba(159,80,58,1) 0%, rgba(144,80,66,1) 100%) !important;
  }

}
