.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.action-glow {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $blur-shadow;
    border-radius: 50%;
    pointer-events: none;
    content: "";
  }

  &:before {
    width: 24.625rem;
    height: 13.94rem;
    filter: blur(5.68rem);
    mix-blend-mode: normal;
  }

  &:after {
    width: 21.5rem;
    height: 6.25rem;
    filter: blur(2.82rem);
  }

  > button, a {
    position: relative;
    z-index: 3;
  }
}

.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, .24);
}
