$noiseUrl: "https://s3.us-east-2.amazonaws.com/beam.cam/static_content/assets/images/noise-big-default.png";
$primaryFont: 'Graphik', sans-serif;
$secondaryFont: 'MobeType';

$bg-body: #17002A;
$copy: #fff;
$link: #66EAFF;

$shadow: 0 0 .3125rem rgba(102, 234, 255, 0.8), 0 0 .9375rem rgba(102, 234, 255, 0.8), 0 0 1.25rem rgba(102, 234, 255, 0.8);
$shadow-lg: 0 0 .625rem rgba(102, 234, 255, 0.8), 0 0 1.875rem rgba(102, 234, 255, 0.8), 0 0 2.1rem rgba(102, 234, 255, 0.8);
$blur-shadow: rgba(171, 102, 255, .7);
$bg-noise: url($noiseUrl), linear-gradient(135.49deg, rgba(255, 255, 255, 0.25) -29.7%, rgba(255, 255, 255, 0) 99.89%), rgba(0, 0, 0, 0.4);
